<template>
  <div class="complaint-page">
    <div class="content">
      <h1 class="header">免责声明</h1>
      <div class="input-line">
        <p>在使用本网站网盘搜索引擎（以下简称本网站）前，请您务必仔细阅读并透彻理解本声明。您可以自愿选择是否使用本网站，但如果您使用本网站，您的使用行为将被视为对本声明全部内容的认可。</p>
        <br>
        <p>1.本网站服务：本服务可向您提供与其他国际互联网上之网站或资源之链接。但是，您了解并明确知悉本网站无法控制这些网站及资源，因您使用或依赖任何由这些网站或资源发布的或经由这些网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，我们无法对此承担任何直接或间接责任。</p>
        <br>
        <p>2.鉴于本网站以非人工检索方式、根据您输入的关键字自动生成到第三方网页的链接，以便用户能够找到和使用第三方网站上各种文档、图片及其他所需内容。本网站本身不储存、复制、传播、控制编辑或修改被链接的第三方网站上的信息内容或其表现形式。对其概不负责，亦不承担任何法律责任。</p>
        <br>
        <p>3.任何通过使用本网站而搜索链接到的第三方网站资源均系他人制作或提供，您可能从该第三方网站上获得资源及享用服务，本网站对其合法性概不负责，亦不承担任何法律责任。</p>
        <br>
        <p>4.本网站搜索结果根据您键入的关键字自动搜索获得并生成，不代表本网站赞成被搜索链接到的第三方网站上的内容或立场。</p>
        <br>
        <p>5.您应该对使用本网站的结果自行承担风险。本网站不做任何形式的保证：不保证搜索结果满足您的要求，不保证搜索服务不中断，不保证搜索结果的安全性、正确性、及时性、合法性，不保证由于您使用服务而获得的信息将是准确的或可靠的；因网络状况、通讯线路、第三方网站等任何原因而导致您不能正常使用本网站，本网站不承担任何法律责任。</p>
        <br>
        <p>6.本网站高度重视知识产权保护，若本网站收录的第三方网页内容无意侵犯了您的权益，请提供相关有效书面证明发往 cixin64462800@163.com，我们核实后单方面进行删改页面。详情请参见<a href="/about/version">《版权说明》</a>。</p>
        <br>
        <p>7.本网站尊重并保护您的个人隐私权，本网站无注册会员、留言、评论、交易等交互功能，因此您的用户名、电子邮件地址、QQ号、微信号、证件号等一切个人资料不会被本网站所记录或储存，更不存在主动地泄露或以任何形式向任何第三方提供您个人资料的行为。但是，您了解并同意：您在使用搜索引擎时输入的关键字将不被认为是您的个人隐私资料。</p>
        <br>
        <p>8.本网站是个非经营性个人网站，旨在方便用户查找学习资料，本网站坚决打击利用网盘及其搜索服务侵犯他人个人隐私和盗版等违法行为，本站对一些非法恶意的搜索进行了屏蔽，屏蔽的词库正在不断的扩大完善中，如您发现有任何不良信息，欢迎举报反馈给我们，举报邮箱: cixin64462800@163.com，我们将尽快处理。
        </p>
      </div>
      <div class="menu">
        <ul>
            <li><router-link to="/about/policy">免责声明</router-link></li>
          <li><router-link to="/about/agree">用户协议</router-link></li>
        </ul>
		  </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_var.scss';
.complaint-page {
  min-height: 100%;
  background-color: #f8f8f8;
}
.content {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 15px;
  line-height: 1.6;
  text-align: center;
  word-break: break-all;
  .complaint-image {
    width: 60px;
    margin-bottom: 10px;
  }
  .header {
    margin-bottom: 10px;
  }
  .input-line {
    text-align: left;
    margin-bottom: 15px;
  }
  .menu {
    width: 100%;
    border-top:1px solid $theme-color;
    height: 40px;
    margin-top:50px;
    ul {
      margin:0px;
      list-style:none; 
      li {
        float:right;
        margin-left:30px;
      a {
          color:$theme-color;
        }
      } 
    } 
  }
}
@media (max-width: 700px) {
  .content {
    padding: 20px;
  }
}
</style>